import React from "react";
import Image from "next/image";
import makeStyles from "@mui/styles/makeStyles";
// @mui/icons-material
// import TrendingUp from "@mui/icons-material/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
// import Danger from "components/Typography/Danger.js";
// import Success from "components/Typography/Success.js";
// import Button from "components/CustomButtons/Button.js";

import blogsStyle from "styles/jss/nextjs-material-kit-pro/pages/sectionsSections/blogsStyle.js";

const useStyles = makeStyles(blogsStyle);

export default function SectionBlogs({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Blogs 1 START */}
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>Latest Blogposts</h2>


             
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="/blog-post/2024/01/using-streamlit-to-build-web-applications">
                        <img
                          src="/img/posts/2024-01-15-using-streamlit-to-build-web-applications/streamlit_header.png"
                          alt="..."
                        />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage:
                            "url('/img/posts/2024-01-15-using-streamlit-to-build-web-applications/streamlit_header.png')",
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <Info>
                      <h6 className={classes.cardCategory}>Data</h6>
                    </Info>
                    <h3 className={classes.cardTitle}>
                      <a href="/blog-post/2024/01/using-streamlit-to-build-web-applications">
                      Using Streamlit To Build Web Applications
                      </a>
                    </h3>
                    <p className={classes.description1}>
                    We are an Australian Analytics | Web Development | Digital Marketing Agency based in Melbourne. We can help you with all types of applications such as Streamlit !
                      <a href="/blog-post/2024/01/using-streamlit-to-build-web-applications"> Read More </a>
                    </p>
                    <p className={classes.author}>
                      by{" "}
                      <a href="/about/profile-robert-dzudzar">
                        <b>Robert Dzudzar</b>
                      </a>{" "}
                      , 10-January-2024
                    </p>
                  </GridItem>
                </GridContainer>
              </Card>




              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={7} md={7}>
                    <Info>
                      <h6 className={classes.cardCategory}>
                        Looker Studio
                      </h6>
                    </Info>
                    <h3 className={classes.cardTitle}>
                      <a href="/blog-post/2023/12/ga4-custom-dimensions-in-looker-studio">
                      GA4 Custom Dimensions in Looker Studio (Data Studio) - Tribalism
                      </a>
                    </h3>
                    <p className={classes.description1}>
                    Can you get GA4 Custom Dimensions and metrics into Looker Studio? The quick answer is... YES! Scroll down more to find out the
                specifics....
                      <a href="/blog-post/2023/12/ga4-custom-dimensions-in-looker-studio">
                        {" "}
                        Read More{" "}
                      </a>
                    </p>
                    <p className={classes.author}>
                      by{" "}
                      <a href="/about/profile-scott-sunderland">
                        <b>Scott Sunderland</b>
                      </a>{" "}
                      , 10-December-2023
                    </p>
                  </GridItem>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="/blog-post/2023/12/ga4-custom-dimensions-in-looker-studio">
                        <img
                          src="/img/posts/2023-12-13-ga4-custom-dimensions-in-looker-studio/bp202312-4.png"
                          alt="..."
                        />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage:
                            "url('/img/posts/2023-12-13-ga4-custom-dimensions-in-looker-studio/bp202312-4.png')",
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                </GridContainer>
              </Card>



              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a
                        href="/blog-post/2022/10/measurecamp-sydney-2022"
                        onClick={(e) => e.preventDefault()}
                      >
                        <Image
                          layout="responsive"
                          width={365}
                          height={192}
                          src="/img/posts/2022-10-28-measurecampsydney2022/mc-syd2022-1.jpg"
                        />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage:
                            "url('/img/posts/2022-10-28-measurecampsydney2022/mc-syd2022-1.jpg')",
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <Info>
                      <h6 className={classes.cardCategory}>NEWS</h6>
                    </Info>
                    <h3 className={classes.cardTitle}>
                      <a href="/blog-post/2022/10/measurecamp-sydney-2022">
                      Measurecamp Sydney 2022
                      </a>
                    </h3>
                    <p className={classes.description1}>
                    The fifth?? Ive lost count...{" "}
            <a href="https://sydney.measurecamp.org/">Measurecamp Sydney</a>{" "}
            event occured on October 22nd 2022. Tribalism was there to join in
            the fun at the first Measurecamp in Australia since October 2019 in
            Sydney.
                      <a href="/blog-post/2022/10/measurecamp-sydney-2022">
                        {" "}
                        Read More{" "}
                      </a>
                    </p>
                    <p className={classes.author}>
                      by{" "}
                      <a href="/about/profile-scott-sunderland">
                        <b>Scott Sunderland</b>
                      </a>{" "}
                      , 20-October-2022
                    </p>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Blogs 1 END */}
    </div>
  );
}
